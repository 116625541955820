<app-header></app-header>

<section class="container mt-5 pt-5 mb-5">
    <h1 class="licenses-text">Licenses</h1>
    <div class="rich-text text-page__content">
      <p>
        <strong
          >Spruce Lending, Inc. and Solar Service Experts, LLC are registered
          with the Nationwide Multistate Licensing System &amp; Registry and
          hold applicable state licenses where required</strong
        >
      </p>
      <table style="height: 1400.79px; width: 100%;border: 1px solid #eee" >
        <tbody>
          <tr style="height: 4.78749px">
            <td
              style="height: 20.7875px; width: 598.013px"
              colspan="2"
              rowspan="2"
            >
              &nbsp;
            </td>
          </tr>
          <tr style="height: 10px">
            
          </tr>
          <tr style="height: 10px">
            <td style="height: 10px" colspan="2">
              <strong
                >Learn more at:&nbsp;&nbsp; www.NMLSConsumerAccess.org</strong
              >
            </td>
          </tr>
          <tr style="height: 10px">
            <td style="height: 10px">&nbsp;</td>
            <td style="height: 10px">&nbsp;</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px" colspan="2">
              Solar Service Experts, LLC (NMLS ID # 1734138)
            </td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">Arizona</td>
            <td style="height: 23px">CA-0950346</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">City of Buffalo, NY</td>
            <td style="height: 23px">10116</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">City of New York, NY</td>
            <td style="height: 23px">CAG18-10048614</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">City of Yonkers, NY</td>
            <td style="height: 23px">2078987-DCA</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">Colorado</td>
            <td style="height: 23px">CAR-1000510</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">Colorado</td>
            <td style="height: 23px">SFN-6000116</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">Colorado</td>
            <td style="height: 23px">RSL-5001284</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">Connecticut</td>
            <td style="height: 23px">CCA-1734138</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">Connecticut</td>
            <td style="height: 23px">SFC-1734138</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">Florida</td>
            <td style="height: 23px">SF9901792</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">Florida</td>
            <td style="height: 23px">RS9909492</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">Florida</td>
            <td style="height: 23px">RSB9909427</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">Hawaii</td>
            <td style="height: 23px">COLAX 1042</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">Louisiana</td>
            <td style="height: 23px">43024022I</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">Louisiana</td>
            <td style="height: 23px">#013493</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">Maryland</td>
            <td style="height: 23px">1734138</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">New Jersey</td>
            <td style="height: 23px">
              17175&nbsp; (formerly 16726)
            </td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">New Jersey</td>
            <td style="height: 23px">1904124</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">New Mexico</td>
            <td style="height: 23px">—</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px">Texas</td>
            <td style="height: 23px">
              20200016&nbsp; (File Number)
            </td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px; ">Washington</td>
            <td style="height: 23px">604-308-595</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px; ">Washington</td>
            <td style="height: 23px">604-308-595</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px; ">Wisconsin</td>
            <td style="height: 23px">—</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px; ">—</td>
            <td style="height: 23px">&nbsp;</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px; ">Spruce Power 1, LLC</td>
            <td style="height: 23px">&nbsp;</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px; ">Colorado</td>
            <td style="height: 23px">SF-992107</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px; ">New Jersey</td>
            <td style="height: 23px">L069216</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px; ">—</td>
            <td style="height: 23px">&nbsp;</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px; ">Spruce Power 3, LLC</td>
            <td style="height: 23px">&nbsp;</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px; ">Colorado</td>
            <td style="height: 23px">SFN-6000116</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px; ">Florida</td>
            <td style="height: 23px">SF9901917</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px; ">Texas</td>
            <td style="height: 23px">2000068550-219272</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px; ">—</td>
            <td style="height: 23px">&nbsp;</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px; ">Spruce Storage, LLC</td>
            <td style="height: 23px">&nbsp;</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px; ">Texas</td>
            <td style="height: 23px">2100070328-220357</td>
          </tr>
          <tr style="height: 23px">
            <td style="height: 23px; ">—</td>
            <td style="height: 23px">&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
<app-footer></app-footer>