import { Component } from '@angular/core';
import { HeaderComponent } from "../header/header.component";
import { FooterComponent } from "../footer/footer.component";

@Component({
  selector: 'app-licenses',
  standalone: true, 
  templateUrl: './licenses.component.html',
  styleUrl: './licenses.component.scss',
  imports: [HeaderComponent, FooterComponent]
})
export class LicensesComponent {

}